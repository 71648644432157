<template>
	<div style="">
		<a-tabs v-model="queryParam.orderInfo.orderstatus" type="card" @change="tabsChange">
			<a-tab-pane v-for="pane in panes" :key="pane.key" :tab="pane.title">
			</a-tab-pane>
		</a-tabs>
		<!-- 条件搜索 -->
		<div style="margin-bottom: 10px;padding: 16px 32px;background: #fff;" ref="search">
			<a-form :labelCol="labelCol" :wrapperCol="wrapperCol">
				<a-row :gutter="48">
					<!-- 订单状态 -->
					<!-- <a-col :md="4" :sm="24">
						<a-form-item label="订单状态">
							<a-select v-model="queryParam.orderTypeIndex" style="width: 120px">
								<a-select-option v-for="(item,index) in orderTypeDiction" :key="index"
									:value="item.value">
									{{item.label}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col> -->

					<!-- 订单类型 -->
					<!-- <a-col :md="4" :sm="24">
						<a-form-item label="订单类型">
							<a-select v-model="queryParam.orderClassIndex" allowClear style="width: 120px">
								<a-select-option v-for="(item,index) in orderClassDiction" :key="index"
									:value="item.value">
									{{item.label}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col> -->
					
					<!-- 是否反单 -->
					<!-- <a-col :md="4" :sm="24">
						<a-form-item label="是否反单">
							<a-select v-model="queryParam.antiSingleIndex" allowClear style="width: 120px">
								<a-select-option v-for="(item,index) in antiSingleDiction" :key="index"
									:value="item.value">
									{{item.label}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col> -->
					
					<!-- 支付方式 -->
					<!-- <a-col :md="4" :sm="24">
						<a-form-item label="支付方式">
							<a-select v-model="queryParam.paymentMethodIndex" allowClear style="width: 120px">
								<a-select-option v-for="(item,index) in antiSingleDiction" :key="index"
									:value="item.value">
									{{item.label}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col> -->
					
					<!-- 订单号 -->
					<a-col :md="4" :sm="24">
						<a-form-item label="订单号">
							<a-input v-model.trim="queryParam.orderInfo.orderno" />
						</a-form-item>
					</a-col>
					

					<!-- 确认日期 -->
					<!-- <a-col :md="8" :sm="24" v-if="advanced">
						<a-form-item label="确认时间">
							<a-range-picker :format="'YYYY-MM-DD'" :placeholder="['开始时间', '结束时间']"
								@change="confirmDateChange">
								<template slot="dateRender" slot-scope="current">
									<div class="ant-calendar-date" :style="getCurrentStyle(current)">
										{{ current.date() }}
									</div>
								</template>
							</a-range-picker>
						</a-form-item>
					</a-col> -->

					<!-- 支付日期 -->
					<!-- <a-col :md="8" :sm="24" v-if="advanced">
						<a-form-item label="支付时间">
							<a-range-picker :format="'YYYY-MM-DD'" :placeholder="['开始时间', '结束时间']"
								@change="paymentDateChange">
								<template slot="dateRender" slot-scope="current">
									<div class="ant-calendar-date" :style="getCurrentStyle(current)">
										{{ current.date() }}
									</div>
								</template>
							</a-range-picker>
						</a-form-item>
					</a-col> -->

					<!-- 下单日期 -->
					<a-col :md="8" :sm="24">
						<a-form-item label="下单时间">
							<a-range-picker :format="'YYYY-MM-DD'" v-model="createValue" :placeholder="['开始时间', '结束时间']"
								@change="placeOrderDateChange">
								<template slot="dateRender" slot-scope="current">
									<div class="ant-calendar-date" :style="getCurrentStyle(current)">
										{{ current.date() }}
									</div>
								</template>
							</a-range-picker>
						</a-form-item>
					</a-col>

					<a-col>
						<span class="table-page-search-submitButtons" style="float: right;">
							<a-button type="primary" @click="handleQuery" style="background-color:#f2ab15;border: none">
								<a-icon type="search" />查询
							</a-button>
							<a-button style="margin-left: 8px" @click="resetQuery">
								<a-icon type="redo" />重置
							</a-button>
							<a @click="toggleAdvanced" style="margin-left: 8px">
								{{ advanced ? '收起' : '展开' }}
								<a-icon :type="advanced ? 'up' : 'down'" />
							</a>
						</span>
					</a-col>
				</a-row>
			</a-form>
		</div>

		<modifyMode :dataInfo="modifyOrderInfo" :open="open" :columns="columnsDetails" @close="cancel" @ok="pageByFieldData"></modifyMode>
		<detailsModel :open="detailsOpen" :details="details" :columns="columnsDetails" @close="detailsCancel"></detailsModel>
		<addressModel :open="addOpen" :defaultForm="addressForm" @close="addCancel" @ok="submitForm"></addressModel>
		
		<div class="table-page-search-wrapper" style="margin-bottom: 10px;" ref="table">
			<AdvanceTable :columns="columns" :data-source="list" :title="tableTitle" :loading="loading" :rowKey="record=>record.id"
				:scroll="{ x: 1300 }" size="middle" tableKey="view-text-table" @change="handleTableChange"
				@refresh="getList"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,fixed:true }"
				:format-conditions="true" :pagination="{
			  current: queryParam.pageNum,
			  pageSize: queryParam.pageSize,
			  total: total,
			  showSizeChanger: true,
			  showLessItems: true,
			  showQuickJumper: true,
			  showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条，总计 ${total} 条`,
			  onChange: changeSize,
			  onShowSizeChange: onShowSizeChange,
			}">
				<span slot="creatdate" slot-scope="{text, record}">
					{{filterTime(text)}}
				</span>
				<span slot="orderstatus" slot-scope="{text, record}">
					<a-tag
						:color="text==0 ? 'orange' : text==1 ? 'orange' : text == 2 ? 'red' : text == 3 ? 'blue' : text == 4 ? 'green' : 'purple'">
						{{translate(1,text)}}
					</a-tag>
				</span>
				<span slot="delivertype" slot-scope="{text, record}" v-if="text">
					{{logistics(text) || '暂无'}}
				</span>
				<span slot="delivertype" slot-scope="{text, record}" v-else>
					{{'暂无'}}
				</span>
				<span slot="operation" slot-scope="{text, record}" style="display: flex;justify-content: center;">
					<!-- <a @click="modifyOrder(record)" style="color: #2f54eb;"
						v-if="record.orderstatus==0">
						修改订单
					</a> -->
					<!-- <a-divider type="vertical"
						v-if="record.orderstatus==0" /> -->
					<a @click="orderDetails(record)" style="color: #2f54eb;">
						订单明细
					</a>
					<div v-if="record.orderstatus==0 || record.orderstatus==1 || record.orderstatus==2 || record.orderstatus==3 || record.orderstatus==4">
						<a-divider type="vertical" />
						<a @click="cancelOrder(record)" style="color: #2f54eb;">
							取消订单
						</a>
					</div>
				</span>
				<div class="table-operations" slot="button">
					<c-button type="default" :height="33" style="margin-left: 10px;"
						v-if="queryParam.orderInfo.orderstatus==0 || queryParam.orderInfo.orderstatus==1 || queryParam.orderInfo.orderstatus==2 || queryParam.orderInfo.orderstatus==3 || queryParam.orderInfo.orderstatus==undefined" :disabled="selectedRowKeys.length==0 ? false : selectedRowKeys.length==1 ? false :selectedRowKeys.length==2 ? false :selectedRowKeys.length==3 ? false : true"
						@click="modifyAddress">修改地址</c-button>
					<c-button type="default" :height="33" style="margin-left: 10px;" :disabled="selectedRowKeys.length==0 ? false : selectedRowKeys.length==1 ? false :selectedRowKeys.length==2 ? false :selectedRowKeys.length==3 ? false : true" v-if="queryParam.orderInfo.orderstatus==0 || queryParam.orderInfo.orderstatus==1 || queryParam.orderInfo.orderstatus==2 || queryParam.orderInfo.orderstatus==3 || queryParam.orderInfo.orderstatus==4 || queryParam.orderInfo.orderstatus==undefined"
						@click="batchCancel">批量取消</c-button>
					<!-- <c-button type="danger" :height="33" style="margin-left: 10px;" :disabled="selectedRowKeys.length>0 ? false : true" @click="selectDelete"><a-icon type="delete" style="padding-right: 5px;" />删除</c-button> -->
				</div>
			</AdvanceTable>
		</div>
	</div>
</template>

<script>
	import AdvanceTable from '@/components/pt/table/AdvanceTable' //table组件引入
	import modifyMode from './components/modifyMode.vue' //table组件引入
	import CButton from '@/components/CButton/index.vue' //button组件引入
	import detailsModel from './components/detailsModel.vue'
	import addressModel from './components/addressModel.vue'
	import moment from 'moment'
	import Cookies from "js-cookie";
	import {
		pageByField,
		openQuotePage,
		batchOrder
	} from '@/api/orderManagement/index'
	import {
		getDictListByPid
	} from '@/api/home/index'
	import {
			dataInJs
		} from '@/business/valuation/components/data.js';
	export default {
		components: {
			AdvanceTable,
			modifyMode,
			detailsModel,
			addressModel,
			CButton
		},
		data() {
			return {
				dataList: dataInJs(), //字典list数据
				//tabs列表
				tabsIndex: 1,
				panes: [{
					title: '全部订单',
					key: undefined
				}, {
					title: '待报价',
					key: 0
				}, {
					title: '已报价',
					key: 4
				}, {
					title: '审核中',
					key: 5
				}, {
					title: '生产中',
					key: 6
				}, {
					title: '已完成',
					key: 11
				}, {
					title: '已取消',
					key: 99
				}],
				panesOption: [{
					title: '全部订单',
					key: undefined
				}, {
					title: '待报价',
					key: 0
				}, {
					title: '待报价',
					key: 1
				}, {
					title: '待报价',
					key: 2
				}, {
					title: '待报价',
					key: 3
				}, {
					title: '已报价',
					key: 4
				}, {
					title: '审核中',
					key: 5
				}, {
					title: '生产中',
					key: 6
				}, {
					title: '生产中',
					key: 7
				}, {
					title: '生产中',
					key: 8
				}, {
					title: '生产中',
					key: 9
				}, {
					title: '生产中',
					key: 10
				}, {
					title: '已完成',
					key: 11
				}, {
					title: '已取消',
					key: 99
				}],
				orderTypeDiction: [{
						value: 1,
						label: "所有订单"
					},
					{
						value: 2,
						label: "最近一个月"
					},
					{
						value: 3,
						label: "最近三个月"
					},
					{
						value: 4,
						label: "最近半年"
					},
					{
						value: 5,
						label: "最近一年"
					}
				],
				orderClassDiction: [{
					value: 1,
					label: "样板"
				},
				{
					value: 2,
					label: "小批量"
				}],
				antiSingleDiction:[{
					value: 1,
					label: "新单"
				},
				{
					value: 2,
					label: "反单"
				}],
				paymentMethodDiction:[{
					value: 1,
					label: "等待支付"
				},
				{
					value: 2,
					label: "支付宝"
				},
				{
					value: 3,
					label: "预付款支付"
				},
				{
					value: 4,
					label: "微信"
				},
				{
					value: 5,
					label: "线下转账"
				},
				{
					value: 6,
					label: "信用付"
				}],
				//是否打开弹框
				open: false,
				//是否打开详情弹框
				detailsOpen: false,
				//新增地址弹框
				addOpen: false,
				//详情对象
				details:undefined,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 18
				},
				// 高级搜索 展开/关闭
				advanced: false,
				// 传参
				queryParam: {
					pageNum: 1,
					pageSize: 10,
					orderInfo: {
						orderattr: 4,
						uid: undefined,
						orderstatus: undefined, //订单状态
						orderno: undefined, //订单号
						creatsdate: undefined,//下单开始时间
						createdate: undefined,//下单结束时间
					},
					// confirmedStartDate: undefined, //确认开始时间
					// confirmedEndDate: undefined, //确认结束时间
					// paymentStartDate: undefined, //支付开始时间
					// paymentEndDate: undefined, //支付结束时间
					// placeOrderStartDate: undefined, //下单开始时间
					// placeOrderEndDate: undefined, //下单开始时间
					// orderTypeIndex: 1, //订单状态
					// orderClassIndex: undefined,//订单类型
					// antiSingleIndex: undefined,//订单反单
					// paymentMethodIndex: undefined,//支付方式
				},
				//table
				columns: [{
						title: '订单号',
						dataIndex: 'orderno',
						width: 200,
					},
					// {
					// 	title: '产品图片',
					// 	dataIndex: 'image',
					// 	width: 120,
					// 	scopedSlots: {
					// 		customRender: 'image'
					// 	}
					// },
					{
						title: '订单状态',
						dataIndex: 'orderstatus',
						width: 130,
						scopedSlots: {
							customRender: 'orderstatus'
						},
					},
					{
						title: '文件名',
						dataIndex: 'filename',
						width: 300,
					},
					{
						title: '下单时间',
						sorter: (a, b) => a.creatdate > b.creatdate,
						dataIndex: 'creatdate',
						width: 150,
						ellipsis: true,
						sorter: true,
						scopedSlots: {
							customRender: 'creatdate'
						},
					},
					// {
					// 	title: '交期',
					// 	dataIndex: 'deliveryDate',
					// 	width: 150,
					// },
					{
						title: '快递类型',
						dataIndex: 'delivertype',
						width: 350,
						// align: 'center'
						scopedSlots: {
							customRender: 'delivertype'
						},
					},
					{
						title: '快递费用',
						dataIndex: 'deliverprice',
						width: 100,
						// align: 'center'
					},
					{
						title: '交货地址',
						dataIndex: 'address',
						width: 300,
						// align: 'center'
					},
					{
						title: '数量',
						dataIndex: 'purchaseamount',
						width: 150,
						align: 'center'
					},
					{
						title: '采购备注',
						dataIndex: 'purchasedesc',
						width: 150,
						ellipsis: true,
						sorter: true,
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: 250,
						fixed: 'right',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				//列表数据
				list: [],
				//table名
				tableTitle: 'BOM订单管理',
				loading: false,
				//总数量
				total: 0,
				//选中数组
				selectedRowKeys: [],
				selectedType:true,
				modifyOrderInfo:null,
				createValue: [],//清空日期
				addressForm: {
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					id: undefined,//id
					type: false, //是否默认
				},
				columnsDetails: [{
					title: '序号',
					dataIndex: 'id',
					width: 150,
					key:'序号',
				}, {
					title: '完整型号',
					dataIndex: 'bomtype',
					width: 150,
					key:'完整型号',
				}, {
					title: '品牌',
					dataIndex: 'bombrand',
					width: 150,
					key:'品牌',
				}, {
					title: '封装',
					dataIndex: 'bompack',
					width: 150,
					key:'封装',
				}, {
					title: '参数',
					dataIndex: 'bomparam',
					width: 150,
					key:'参数',
				}, {
					title: '数量',
					dataIndex: 'bomamount',
					width: 150,
					key:'数量',
				}, {
					title: '含税单价',
					dataIndex: 'bomtaxprice',
					width: 150,
					key:'含税单价',
				}, {
					title: '年份',
					dataIndex: 'bomyear',
					width: 150,
					key:'年份',
				}, {
					title: '备注',
					dataIndex: 'bommark',
					width: 150,
					key:'备注',
				}],
				userInfo:null,//个人信息
				getDictList:null,//快递类型
			}
		},

		created() {
			// console.log("created")
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.queryParam.orderInfo.uid=this.userInfo.id
			this.pageByFieldData()
			this.getDictListByPid();
		},
		
		activated(){
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.queryParam.orderInfo.uid=this.userInfo.id
			this.pageByFieldData()
		},

		mounted() {
			
		},

		watch: {

		},

		computed: {
			
		},

		methods: {
			//----------------------- 获取订单列表数据 -----------------------------
			pageByFieldData: function() {
				pageByField(this.queryParam).then(res => {
					this.open=false;
					if(res.data!==null){
						this.list = res.data.content
						this.total = res.data.totalSize
					}else{
						this.list = []
						this.total = 0
					}
				})
			},
			
			//----------------------获取字典数据------------------------
			getDictListByPid:function(){
				let data={
					pid:142
				}
				getDictListByPid(data).then(res=>{
					this.getDictList=res.content
					// console.log(this.getDictList)
				})
			},
			
			//----------------------- 搜索按钮操作 ---------------------------
			handleQuery: function() {
				this.pageByFieldData()
			},

			//--------------------------- 重置 ---------------------------
			resetQuery: function() {
				this.queryParam.pageNum=1;
				this.queryParam.pageSize=10;
				this.queryParam.orderInfo.orderno=undefined;
				this.queryParam.orderInfo.creatsdate=undefined;
				this.queryParam.orderInfo.createdate=undefined;
				this.createValue = [];
				this.pageByFieldData()
			},

			//----------------------- 收起/展出----------------------------
			toggleAdvanced: function() {
				this.advanced = !this.advanced
			},


			handleTableChange: function(pagination, filters, sorter) {

			},

			//-------------------获取数据列表---------------------------
			getList: function() {

			},

			changeSize: function(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				// console.log("changeSize")
				this.pageByFieldData()
			},
			onShowSizeChange: function(current, pageSize) {
				this.queryParam.pageSize = pageSize
				// console.log("onShowSizeChange")
				this.pageByFieldData()
			},

			//-----------------多选/选择表格------------------------------
			onSelectChange(selectedRowKeys, selectedRows) {
				// console.log("onSelectChange")
				this.selectedRowKeys = selectedRowKeys
				this.selectedRowKeys.map(res=>{
					this.list.map(res2=>{
						if(res==res2.id){
							if(res2.orderstatus==0){
								this.selectedType=true
							}else if(res2.orderstatus==1){
								this.selectedType=true
							}else if(res2.orderstatus==2){
								this.selectedType=true
							}else if(res2.orderstatus==3){
								this.selectedType=true
							}else if(res2.orderstatus==4){
								this.selectedType=true
							}else{
								this.selectedType=false
							}
						}
					})
				})
			},

			//------------------跳转新增页面-------------------------
			routeClick: function(e) {
				this.$router.push({
					path: '/order/productAdd',
				})
			},

			//------------------------弹窗取消按钮-------------------------------
			cancel: function() {
				// console.log("cancel")
				this.open = false;
			},
			
			//--------------------详情弹框--------------------------
			detailsCancel:function(){
				this.detailsOpen = false;
			},

			//----------------时间选择器样式--------------------------------
			getCurrentStyle(current, today) {
				const style = {};
				if (current.date() === 1) {
					style.border = '1px solid #f2ab15';
					style.borderRadius = '50%';
				}
				return style;
			},

			//----------------------选择开始时间---------------------------------
			confirmDateChange: function(date, dateString) {
				// console.log(dateString)
				this.queryParam.confirmedStartDate = dateString[0]
				this.queryParam.confirmedEndDate = dateString[1]
			},

			//--------------------选择支付时间范围-----------------------
			paymentDateChange: function(date, dateString) {
				this.queryParam.paymentStartDate = dateString[0] + " 00:00:00"
				this.queryParam.paymentEndDate = dateString[1] + " 00:00:00"
			},

			//--------------------下单时间范围---------------------
			placeOrderDateChange: function(date, dateString) {
				this.queryParam.orderInfo.creatsdate = dateString[0]
				this.queryParam.orderInfo.createdate = dateString[1]
			},
			
			//------------------时间戳翻译-----------------------
			filterTime(time) {
				return moment(time).format("YYYY-MM-DD HH:mm:ss")
			},
			
			//----------------物流翻译-----------------------
			logistics:function(e){
				let a = null
				this.getDictList.filter(res => {
					if (e == res.id) {
						a = res.name
					}
				})
				return a
			},
			
			//-----------------订单状态筛选------------------------
			translate: function(e, key) {
				if (e == 1) {
					let a = null
					this.panesOption.filter(res => {
						if (key == res.key) {
							a = res.title
						}
					})
					return a
				}
			},
			
			//------------------订单明细--------------------------
			orderDetails: function(e) {
				let data = {
					id: e.id
				}
				openQuotePage(data).then(res => {
					// console.log(res)
					this.detailsOpen = true;
					this.details = res.data;
					// console.log(this.details)
				})
			},
			
			//------------------取消订单--------------------------
			cancelOrder: function(event) {
				// console.log(event)
				this.$confirm(`是否取消订单号${event.orderno}?`, '取消订单', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  let data = {
				  	orderInfo: [{
				  		id: event.id,
				  		orderstatus: 5
				  	}]
				  }
				  batchOrder(data).then(res => {
				  	this.$message.success(
				  		'取消成功',
				  	)
					this.pageByFieldData()
				  })
				}).catch(() => {});
			},
			
			//----------------批量取消--------------------------
			batchCancel:function(){
				if(this.selectedType==false){
					this.$confirm('请选择待报价、已报价的订单进行操作~', '警告', {
					  confirmButtonText: '确定',
					  type: 'error'
					}).then(() => {}).catch(() => {});
				}else{
					var orderno=[]
					this.selectedRowKeys.map(res=>{
						this.list.map(res2=>{
							if(res==res2.id){
								orderno.push(res2.orderno)
							}
						})
					})
					this.$confirm(`是否取消订单号:${orderno.toString()}?`, '取消订单', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
					  let orderInfo=[]
					  this.selectedRowKeys.map(res=>{
						  let arr={
							  id:res,
							  orderstatus: 5
						  }
						  orderInfo.push(arr)
					  })
					  let data = {
					  	orderInfo: orderInfo
					  }
					  batchOrder(data).then(res => {
					  	this.$message.success(
					  		'取消成功',
					  	)
						this.pageByFieldData()
					  })
					}).catch(() => {});
				}
			},
			
			//--------------------删除订单--------------------------
			selectDelete:function(){
				var orderno=[]
				this.selectedRowKeys.map(res=>{
					this.list.map(res2=>{
						if(res==res2.id){
							orderno.push(res2.orderno)
						}
					})
				})
				// console.log(this.selectedRowKeys)
				this.$confirm(`是否删除订单号:${orderno.toString()}?`, '删除订单', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  let orderInfo=[]
				  this.selectedRowKeys.map(res=>{
					  let arr={
						  id:res,
						  orderstatus: 6
					  }
					  orderInfo.push(arr)
				  })
				  let data = {
				  	orderInfo: orderInfo
				  }
				  batchOrder(data).then(res => {
				  	this.$message.success(
				  		'取消成功',
				  	)
					this.pageByFieldData()
				  })
				}).catch(() => {});
			},
			
			//---------------打开修改订单弹框----------------------
			modifyOrder:function(event){
				let data = {
					id: event.id
				}
				openQuotePage(data).then(res => {
					// console.log(res)
					this.open=true;
					this.modifyOrderInfo=res.data
				})
				
			},
			
			//----------------修改地址-----------------------
			modifyAddress:function(){
				if(this.selectedRowKeys.length!==1){
					this.$confirm('请选择对应单个订单进行操作~', '警告', {
					  confirmButtonText: '确定',
					  type: 'error',
					  center: true, //文字居中显示
					  showCancelButton: false, //不显示取消按钮
					}).then(() => {}).catch(() => {});
				}else{
					let type = false
					this.list.map(res=>{
						if(res.id==this.selectedRowKeys[0]){
							if(res.orderstatus==0 || res.orderstatus==1 || res.orderstatus==2 || res.orderstatus==3 || res.orderstatus==4){
								type=true
							}else{
								type=false
							}
						}
					})
					if(type==true){
						let data = {
							id: this.selectedRowKeys[0]
						}
						openQuotePage(data).then(res => {
							// console.log(res)
							let a = res.data.address.split(" ")
							if(a.length==6){
								this.addressForm.province=a[0]
								this.addressForm.city=a[1]
								this.addressForm.area=a[2]
								this.addressForm.streetAdd=a[3]
								this.addressForm.contacts=a[4]
								this.addressForm.phone=a[5]
								this.addressForm.id=this.selectedRowKeys[0]
							}else{
								this.addressForm.province=a[0]
								this.addressForm.city=a[1]
								this.addressForm.area=a[2]
								this.addressForm.streetAdd=a[3]
								this.addressForm.contacts=a[4]
								this.addressForm.phone=a[5]
								this.addressForm.company=a[6]
								this.addressForm.id=this.selectedRowKeys[0]
							}
							this.addOpen = true;
						})
					}else{
						this.$confirm('请选择待报价的订单进行操作~', '警告', {
						  confirmButtonText: '确定',
						  type: 'error',
						  center: true, //文字居中显示
						  showCancelButton: false, //不显示取消按钮
						}).then(() => {}).catch(() => {});
					}
				}
			},
			
			//---------------关闭弹框------------------------------
			addCancel: function() {
				this.addOpen = false;
			},
			
			//-----------------确认地址弹框回调----------------------
			submitForm: function() {
				this.addressForm = {
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					type: false, //是否默认
				}
				this.addOpen=false;
				this.pageByFieldData()
			},
			
			//----------------------tabs回调-------------------------------
			tabsChange: function(event) {
				// console.log(event)
				this.pageByFieldData()
			},
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .ant-tabs-ink-bar {
		background-color: #f2ab15 !important;
	}

	/deep/ .ant-tabs-tab:hover {
		color: #f2ab15 !important;
	}

	/deep/ .ant-tabs-bar {
		margin: 0 !important;
	}

	/deep/ .ant-tabs-tab-active {
		color: #f2ab15 !important;
	}
	
	// /deep/ .ant-table {
	//   min-height: calc(100vh - 240px) !important;
	// }
	// /deep/ .ant-table-body {
	//   min-height: calc(100vh - 240px) !important;
	// }
</style>
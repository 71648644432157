import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
import CButton from '@/components/CButton/index.vue' //button组件引入
export default {
	components: {
		AntModal,
		CButton
	},
	props: {
		open: { // 按钮默认文本
			type: Boolean,
			default: false
		},
		defaultPlainOptions:{
			type: Array,
			default: () => []
		},
		dataList:{
			type:Array,
			default: () => []
		},
		defaultColumns:{
			type:Array,
			default: () => []
		},
		bomForm:{
			type:Object,
			default: () => {}
		}
	},
	data() {
		return {
			modalOpen: this.open, //弹窗打开关闭
			formTitle: "选择BOM", //弹框名
			checkedList:[],
			indeterminate: false,
			checkAll: false,
			plainOptions:this.defaultPlainOptions,
			data:this.dataList,
			columns:this.defaultColumns,
			gaugeOutfit:[],//表单选择的表头列表
			form:{
				bomtype:undefined,
				bombrand:undefined,
				bompack:undefined,
				bomparam:undefined,
				bomamount:undefined,
				bomtaxprice:undefined,
				bomyear:undefined,
				bomnotes:undefined
			},
			rules:{
				bomtype:[{
					required: true,
					message: '请选择对应完整型号列名',
					trigger: 'blur'
				}],
				bombrand:[{
					required: true,
					message: '请选择对应品牌列名',
					trigger: 'blur'
				}],
				bompack:[{
					required: true,
					message: '请选择对应封装列名',
					trigger: 'blur'
				}],
				bomparam:[{
					required: true,
					message: '请选择对应参数列名',
					trigger: 'blur'
				}],
				bomamount:[{
					required: true,
					message: '请选择对应数量列名',
					trigger: 'blur'
				}],
				bomtaxprice:[{
					required: true,
					message: '请选择对应含税单价列名',
					trigger: 'blur'
				}],
				bomyear:[{
					required: true,
					message: '请选择对应年份列名',
					trigger: 'blur'
				}],
				bomnotes:[{
					required: true,
					message: '请选择对应备注列名',
					trigger: 'blur'
				}]
			}
		}
	},

	created() {
		
	},

	mounted() {
		
	},

	watch: {
		defaultPlainOptions(val){
			this.plainOptions=val
		},
		dataList(val){
			this.data=val
		},
		defaultColumns(val){
			this.columns=val
		},
		bomForm(val){
			this.onChange(val)
		}
	},

	computed: {
		
	},

	methods: {

		//------------------------取消按钮-------------------------------
		cancel: function() {
			// console.log("cancel")
			this.modalOpen = false;
			this.$emit('close');
		},

		//------------------------提交按钮--------------------------------
		submitForm: function() {
			this.$refs.form.validate(valid => {
				if (valid) {
					var data = this.dataObj(this.gaugeOutfit)
					// console.log("ok1",this.gaugeOutfit)
					console.log("ok2",data)
					this.$emit('ok',this.gaugeOutfit,data,this.form)
				}else{
					this.$message.error(
						'请先选择bom表单中的列名~',
					)
				}
			})
		},
		
		//---------------------dataObj-----------------
		dataObj:function(e){
			var list=[]
			var i = 0
			console.log("ok1",this.dataList)
			this.dataList.map(dataList=>{
				let data={}
				i++
				e.map(res=>{
					data['bomnum'] = i
					if(res==this.form.bomtype){
						data['bomtype']=dataList.[this.form.bomtype]
					}else if(res==this.form.bombrand){
						data['bombrand']=dataList.[this.form.bombrand]
					}else if(res==this.form.bompack){
						data['bompack']=dataList.[this.form.bompack]
					}else if(res==this.form.bomparam){
						data['bomparam']=dataList.[this.form.bomparam]
					}else if(res==this.form.bomamount){
						data['bomamount']=dataList.[this.form.bomamount]
					}else if(res==this.form.bomtaxprice){
						data['bomtaxprice']=dataList.[this.form.bomtaxprice]
					}else if(res==this.form.bomyear){
						data['bomyear']=dataList.[this.form.bomyear]
					}else if(res==this.form.bomnotes){
						data['bomnotes']=dataList.[this.form.bomnotes]
					}
				})
				list.push(data)
			})
			return list
		},
		
		//---------------------完整型号选择列-------------------
		onChange:function(event){
			this.gaugeOutfit=[]
			if(this.form.bomtype!==undefined){
				this.gaugeOutfit.push(this.form.bomtype)
			}
			if(this.form.bombrand!==undefined){
				this.gaugeOutfit.push(this.form.bombrand)
			}
			if(this.form.bompack!==undefined){
				this.gaugeOutfit.push(this.form.bompack)
			}
			if(this.form.bomparam!==undefined){
				this.gaugeOutfit.push(this.form.bomparam)
			}
			if(this.form.bomamount!==undefined){
				this.gaugeOutfit.push(this.form.bomamount)
			}
			if(this.form.bomtaxprice!==undefined){
				this.gaugeOutfit.push(this.form.bomtaxprice)
			}
			if(this.form.bomyear!==undefined){
				this.gaugeOutfit.push(this.form.bomyear)
			}
			if(this.form.bomnotes!==undefined){
				this.gaugeOutfit.push(this.form.bomnotes)
			}
			this.plainOptions = this.defaultPlainOptions
			this.plainOptions = this.plainOptions.filter(item1 => !this.gaugeOutfit.some(item2 => item2 === item1))
		},
	}
}

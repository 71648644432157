<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="520" modalWidth="750"
			@cancel="cancel">
			<div slot="content" layout="vertical">
				<a-descriptions title="" size="small" bordered v-if="details">
					<a-descriptions-item label="订单编号">
						{{details.orderno}}
					</a-descriptions-item>
					<a-descriptions-item label="下单时间">
						{{filterTime(details.pcbInfoList[0].createdate)}}<el-link type="success" @click="exportExcel">导出</el-link>
					</a-descriptions-item>
					<a-descriptions-item label="文件名">
						{{details.filename}}
					</a-descriptions-item>
					<a-descriptions-item label="发票类型">
						{{dataList.invoicingMethod[details.invoicetype-1].name}}
					</a-descriptions-item>
					<a-descriptions-item label="下单联系人">
						{{details.ordername}}
					</a-descriptions-item>
					<a-descriptions-item label="联系人手机">
						{{details.orderphone}}
					</a-descriptions-item>
					<a-descriptions-item label="快递类型" :span="1">
						{{details.delivertype}}
					</a-descriptions-item>
					<a-descriptions-item label="快递费用" :span="2">
						{{details.deliverprice}}
					</a-descriptions-item>
					<a-descriptions-item label="收货人地址" :span="3">
						{{details.address}}
					</a-descriptions-item>
				</a-descriptions>
				<a-table :columns="columns" :data-source="details.pcbInfoList" id="outTable" rowKey="id" v-show="hide==true" v-if="details"
					:scroll="{ x: 1300 }"></a-table>
			</div>
			<template slot="footer">
				<!-- <a-button type="primary" @click="submitForm">
					保存
				</a-button> -->
				<a-button @click="cancel">
					取消
				</a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
	import moment from 'moment'
	import {
		dataInJs
	} from '@/business/valuation/components/data.js';
	import FileSaver from "file-saver";
	const XLSX = require("xlsx");
	export default {
		components: {
			AntModal
		},
		props: {
			open: { // 按钮默认文本
				type: Boolean,
				default: false
			},
			details: {
				type: Object,
				default: () => {}
			},
			columns: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				modalOpen: this.open, //弹窗打开关闭
				hide:false,//是否显示表单
				formTitle: "修改产品", //弹框名
				customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
				dataList: dataInJs(), //字典list数据
			}
		},

		created() {

		},

		mounted() {

		},

		methods: {
			//------------------------取消按钮-------------------------------
			cancel: function() {
				// console.log("cancel")
				this.modalOpen = false;
				this.$emit('close');
			},

			//------------------时间戳翻译-----------------------
			filterTime(time) {
				return moment(time).format("YYYY-MM-DD HH:mm:ss")
			},

			//------------------------提交按钮--------------------------------
			submitForm: function() {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.$message.success(
							'修改成功',
						)
						this.open = false
						this.$emit('ok')
					} else {
						return false
					}
				})
			},

			exportExcel() {
				var xlsxParam = {
					raw: true
				}; //转换成excel时，使用原始的格式
				var wb = XLSX.utils.table_to_book(document.querySelector("#outTable"), xlsxParam); //outTable为列表id
				var wbout = XLSX.write(wb, {
					bookType: "xlsx",
					bookSST: true,
					type: "array"
				});
				try {
					FileSaver.saveAs(
						new Blob([wbout], {
							type: "application/octet-stream;charset=utf-8"
						}),
						this.details.filename + ".xlsx"
					);
				} catch (e) {
					if (typeof console !== "undefined") console.log(e, wbout);
				}
				return wbout;
			}
		}
	}
</script>

<style lang="less" scoped>

</style>
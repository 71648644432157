<template>
	<div style="border: 1px solid #e8eaeb;width: 100%;height: 280px;">
		<div style="padding-top: 20px;padding-left: 20px;display: flex;align-items: center;">
			<img style="width: 20px;height: 20px;"  src="https://koiios-dev.oss-cn-beijing.aliyuncs.com/valuation/bom_title%40ico.png" />
			<span style="padding-left: 10px;font-size: 20px;font-weight: bold;" @click="handleReport"> BOM文件上传</span>
		</div>
		<el-upload ref="targetUpload" style="width: 100%;position: relative;" action="#" :auto-upload="false" :on-change="onChange" :limit="1"
			:show-file-list="false" accept=".xls,.xlsx,.csv" multiple>
			  <div class="bomUpload">
			  		<div style="display: flex;align-items: center;justify-content: center;">
						<i class="el-icon-upload" style="font-size: 40px;"></i>
						<span style="font-size: 20px;">上传一个BOM配单</span>
					</div>
					<div style="font-size: 20px;padding-top: 10px;">（仅支持xls、xlsx、csv格式）文件大小 < 20MB</div>
					<div style="font-size: 14px;padding-top: 5px;color: #999999;">为能更快、更准确的给您报价及生产，请您尽可能的提供以下要求的完整文件!</div>
			  </div>
		</el-upload>
		<xlsx-upload-model :open="open" :defaultPlainOptions="plainOptionsInfo" :defaultColumns="columnsInfo" :bomForm="form" :dataList="dataListInfo" @close="cancel" @ok="submitForm"></xlsx-upload-model>
	</div>
</template>

<script>
	const xlsx = require("xlsx");
	import CButton from '@/components/CButton/index.vue' //button组件引入
	import xlsxUploadModel from './components/xlsxUploadModel2.vue'
	export default {
		components:{
			CButton,
			xlsxUploadModel
		},
		props:{
			//文件名
			xlsxName:{
				type:String,
				default: null
			},
			//表头listArray
			columns: {
				type: Array,
				default: () => []
			},
			//table表头list
			plainOptions:{
				type: Array,
				default: () => []
			},
			//xlsx内容
			dataList:{
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				size:null,//文件大小
				open:false,//是否打开弹框
				xlsxDataList:[],//xlsx列表
				bomList:[],//bom表单数据
				form: null,
				dataListInfo:this.dataList,
				plainOptionsInfo:this.plainOptions,
				columnsInfo:this.columns,
				xlsxNameText:this.xlsxName,
			}
		},
		created() {
			
		},
		methods: {
			async onChange(file) {
				this.$refs.targetUpload.clearFiles()
				/**
				 * 1. 使用原生api去读取好的文件
				 * */
				console.log("原始上传的文件", file);
				this.xlsxNameText = file.name;
				this.size = file.size;
				// 读取文件不是立马能够读取到的，所以是异步的，使用Promise
				let dataBinary = await new Promise((resolve) => {
					// Web API构造函数FileReader，可实例化对象，去调用其身上方法，去读取解析文件信息
					let reader = new FileReader(); // https://developer.mozilla.org/zh-CN/docs/Web/API/FileReader
					// console.log("实例化对象有各种方法", reader);
					reader.readAsBinaryString(file.raw); // 读取raw的File文件
					reader.onload = (ev) => {
						// console.log("文件解析流程进度事件", ev);
						resolve(ev.target.result); // 将解析好的结果扔出去，以供使用
					};
				});
				// console.log("读取出的流文件", dataBinary);

				/**
				 * 2. 使用xlsx插件去解析已经读取好的二进制excel流文件
				  * */
				let workBook = xlsx.read(dataBinary, {
					type: "binary",
					cellDates: true
				});
				// excel中有很多的sheet，这里取了第一个sheet：workBook.SheetNames[0]
				let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]];
				// 分为第一行的数据，和第一行下方的数据
				const header = this.getHeaderRow(firstWorkSheet);
				// console.log("读取的excel表头数据（第一行）", header);
				const data = xlsx.utils.sheet_to_json(firstWorkSheet);
				// console.log("读取所有excel数据", data);
				// console.log("读取所有excel数据", data[1]. ['参数']);
				this.xlsxDataInfo(file,header,data);
			},
			
			getHeaderRow(sheet) {
				const headers = []; // 定义数组，用于存放解析好的数据
				const range = xlsx.utils.decode_range(sheet["!ref"]); // 读取sheet的单元格数据
				let C;
				const R = range.s.r;
				/* start in the first row */
				for (C = range.s.c; C <= range.e.c; ++C) {
					/* walk every column in the range */
					const cell = sheet[xlsx.utils.encode_cell({
						c: C,
						r: R
					})];
					/* find the cell in the first row */
					let hdr = "UNKNOWN " + C; // <-- replace with your desired default
					if (cell && cell.t) hdr = xlsx.utils.format_cell(cell);
					headers.push(hdr);
				}
				return headers; // 经过上方一波操作遍历，得到最终的第一行头数据
			},
			
			//------------xlsx表格数据---------------------------------
			xlsxDataInfo:function(file,header,data){
				this.open = true;
				this.xlsxDataList = [];
				// console.log("读取的excel表头数据（第一行）", header);
				// console.log("读取所有excel数据", data);
				this.plainOptionsInfo = header;
				this.plainOptionsInfo.map(res=>{
					let data = {
						title: res,
						dataIndex: res,
						key: res,
						width: 150,
					}
					this.columnsInfo.push(data)
				})
				this.dataListInfo=data
				data.map(res=>{
					let data= {
						bomtype:res.['完整型号'],
						bombrand:res.['品牌'],
						bompack:res.['封装'],
						bomparam:res.['参数'],
						bomamount:res.['数量'],
						bomtaxprice:res.['含税单价'],
						bomyear:res.['年份'],
						bommark:res.['备份']
					}
					this.xlsxDataList.push(data)
				})
			},
			
			//--------------点击导入------------------------------------
			handleReport() {
			  this.$refs["targetUpload"].$refs["upload-inner"].handleClick()
			},
			
			//---------------选择属性弹框打开--------------------
			selectXlsx:function(){
				this.open=true;
			},
			
			//----------------删除-----------------------
			deleteXlsx:function(){
				this.xlsxNameText = null;
				this.size = null;
				this.plainOptionsInfo = null;
				this.xlsxDataList = [];
				this.dataListInfo = [];
				this.columnsInfo = [];
				this.form = null;
			},
			
			//---------------关闭弹框------------------------------
			cancel:function(){
				this.open=false;
				console.log("2222")
			},
			
			//----------------保存属性--------------------------
			submitForm:function(e,data,form){
				this.bomList=[];
				this.open=false;
				this.form=form;
				console.log("保存属性1",e)
				console.log("保存属性2",data)
				this.$emit('ok',e,data,form)
			}
		},
	};
</script>

<style lang="less" scoped>
	.el-icon-upload{
		color: #d1102d;
	}
	
	.bomUpload{
		width: calc(100% - 40px);
		padding: 40px 20px;
		background: #f8f8f8;
		left: 0;
		position: absolute;
		margin: 20px;
		border: 2px dashed #d8d8d8;
		top: 0;
	}
	
	.bomUpload:hover{
		background: #efefef;
	}
</style>
import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
import CButton from '@/components/CButton/index.vue' //button组件引入
import singleChoice from '@/components/singleChoice/index.vue';
import changeSingleChoice from '@/components/changeSingleChoice/index.vue';
import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
import FileUpload from '@/components/FileUpload/index2.vue';
import { calcStenPrice } from '@/api/home/index.js' 
import {
	updateOrder
} from '@/api/orderManagement/index'
import {
	dataInJs
} from '@/business/valuation/components/data.js';
export default {
	components: {
		singleChoice,
		changeSingleChoice,
		singleChoiceImage,
		singleChoiceColor,
		singleChoicePopup,
		AntModal,
		FileUpload,
		CButton
	},
	props: {
		open: { // 按钮默认文本
			type: Boolean,
			default: false
		},
		dataInfo: {
			type: Object,
			default: () => {}
		},
		columns: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			dataList: dataInJs(), //list数据
			modalOpen: this.open, //弹窗打开关闭
			formTitle: "修改产品", //弹框名
			customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
			priceInfo: null, //订单价格
			plainOptions:[],//table表头list
			radioStyle: {
				display: 'block',
				height: '40px',
				lineHeight: '40px',
			},
			bomQuantityIndex:1,//bom采购数量下标
			bomQuantity:1,//bom采购数量
			purchasemark:null,//采购备注
			uploadList:null,//上传的xslx表格数据
		}
	},

	created() {

	},

	mounted() {

	},

	watch: {
		open(val) {
			// console.log(this.dataInfo)
			this.columns.map(res=>{
				this.plainOptions.push(res.title)
			})
		},
	},

	computed: {

	},

	methods: {
		//------------------------取消按钮-------------------------------
		cancel: function() {
			// console.log("cancel")
			this.modalOpen = false;
			this.$emit('close');
		},
		
		//----------------上传保存属性--------------------------
		uploadForm:function(e,data,form){
			// console.log("保存属性",e)
			// console.log("保存属性",data)
			this.uploadList=data
		},

		//------------------------提交按钮--------------------------------
		submitForm: function() {
			if(this.uploadList!==null){
				this.$confirm('是否保存修改的订单？', '修改订单', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let pcbInfo = []
					this.uploadList.map(res => {
						let a = res
						a['orderattr'] = "4"
						a['id'] = this.dataInfo.pcbInfoList[0].id
						pcbInfo.push(a)
					})
					// console.log(this.uploadList)
					let data = {
						pcbInfo:pcbInfo,
						orderInfo: {
							id: this.dataInfo.id,
							// orderstatus: 0,
							purchaseamount:this.bomQuantity,
							purchasedesc:this.purchasemark
						}
					}
					updateOrder(data).then(res => {
						this.$message.success(
							'修改成功',
						)
						this.modalOpen = false
						this.$emit('ok')
					})
				}).catch(() => {});
			}else{
				this.$message.error(
					'请先重新上传BOM配单~',
				)
			}
		},
	}
}
<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="480" modalWidth="640" @cancel="cancel">
			<!-- 不可选填 -->
			<a-form-model ref="form" :model="form" :rules="rules" slot="content" layout="vertical">
				<a-row class="form-row" :gutter="32">
					<a-col :lg="12" :md="12" :sm="24">
						<a-form-model-item label="完整型号" prop="bomtype">
							<a-select v-model="form.bomtype" allowClear placeholder="请选择对应完整型号列名" show-search @change="onChange($event,1)">
							      <a-select-option v-for="item in plainOptions" :key="item">
							        {{ item }}
							      </a-select-option>
							    </a-select>
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="12" :md="12" :sm="24">
						<a-form-model-item label="品牌" prop="bombrand">
							<a-select v-model="form.bombrand" allowClear placeholder="请选择对应品牌列名" show-search @change="onChange($event,2)">
							      <a-select-option v-for="item in plainOptions" :key="item">
							        {{ item }}
							      </a-select-option>
							    </a-select>
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="12" :md="12" :sm="24">
						<a-form-model-item label="封装" prop="bompack">
							<a-select v-model="form.bompack" allowClear placeholder="请选择对应封装列名" show-search @change="onChange($event,3)">
							      <a-select-option v-for="item in plainOptions" :key="item">
							        {{ item }}
							      </a-select-option>
							    </a-select>
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="12" :md="12" :sm="24">
						<a-form-model-item label="参数" prop="bomparam">
							<a-select v-model="form.bomparam" allowClear placeholder="请选择对应参数列名" show-search @change="onChange($event,4)">
							      <a-select-option v-for="item in plainOptions" :key="item">
							        {{ item }}
							      </a-select-option>
							    </a-select>
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="12" :md="12" :sm="24">
						<a-form-model-item label="数量" prop="bomamount">
							<a-select v-model="form.bomamount" allowClear placeholder="请选择对应数量列名" show-search @change="onChange($event,5)">
							      <a-select-option v-for="item in plainOptions" :key="item">
							        {{ item }}
							      </a-select-option>
							    </a-select>
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="12" :md="12" :sm="24">
						<a-form-model-item label="含税单价" prop="bomtaxprice">
							<a-select v-model="form.bomtaxprice" allowClear placeholder="请选择对应含税单价列名" show-search @change="onChange($event,6)">
							      <a-select-option v-for="item in plainOptions" :key="item">
							        {{ item }}
							      </a-select-option>
							    </a-select>
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="12" :md="12" :sm="24">
						<a-form-model-item label="年份" prop="bomyear">
							<a-select v-model="form.bomyear" allowClear placeholder="请选择对应年份列名" show-search @change="onChange($event,7)">
							      <a-select-option v-for="item in plainOptions" :key="item">
							        {{ item }}
							      </a-select-option>
							    </a-select>
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="12" :md="12" :sm="24">
						<a-form-model-item label="备注" prop="bomnotes">
							<a-select v-model="form.bomnotes" allowClear placeholder="请选择对应备注列名" show-search @change="onChange($event,8)">
							      <a-select-option v-for="item in plainOptions" :key="item">
							        {{ item }}
							      </a-select-option>
							    </a-select>
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="24" :md="24" :sm="24">
					  <div style="padding-top: 20px;">
						  <a-table :columns="columns" :data-source="data" rowKey="序号" :scroll="{ x: 1300 }"></a-table>
					  </div>
					</a-col>
				</a-row>
			</a-form-model>
			<template slot="footer">
			  <a-button type="primary" @click="submitForm">
			    保存
			  </a-button>
			  <!-- <a-button @click="cancel">
			    取消
			  </a-button> -->
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import xlsxUploadModel from './xlsxUploadModel2.js'
	export default {
		...xlsxUploadModel
	}
</script>

<style lang="less" scoped>

</style>

<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="480" modalWidth="640" @cancel="cancel">
			<div slot="content" layout="vertical" v-if="dataInfo">
				<FileUpload :xlsxName="dataInfo.filename" :columns="columns" :plainOptions="plainOptions" :dataList="dataInfo.pcbInfoList" @ok="uploadForm"></FileUpload>
				<div style="padding: 1vw;align-items: center;background: #f8f8f8;">
					<div style="font-size: 1vw;font-weight: bold;">BOM规格填写</div>
					<div style="font-size: 0.8vw;color: #888;padding-top: 5px;">请填写BOM订单信息，上传BOM文件</div>
				</div>
				<div style="padding: 1vw;border: 1px solid #f2f2f2;">
					<!-- 采购数量 -->
					<div style="display: flex;">
						<div class="be_title">
							<div class="be_text">采购数量：</div>
						</div>
						<div style="width: 85%;">
							<a-radio-group v-model="bomQuantityIndex">
								<a-radio :style="radioStyle" :value="1">
									请填写BOM套数
									<input class="input1" type="text" v-model="bomQuantity" />
								</a-radio>
								<a-radio :style="radioStyle" :value="2">
									以BOM列出的订单物料数量
								</a-radio>
							</a-radio-group>
						</div>
					</div>
					
					<!-- 采购备注 -->
					<div style="display: flex;">
						<div class="be_title">
							<div class="be_text">采购备注：</div>
						</div>
						<div style="width: 85%;">
							<el-input
							  type="textarea"
							  :rows="2"
							  placeholder="" v-model="purchasemark">
							</el-input>
						</div>
					</div>
				</div>
			</div>
			<template slot="footer">
			  <a-button type="primary" @click="submitForm">
			    保存
			  </a-button>
			  <a-button @click="cancel">
			    取消
			  </a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import modifyModel from './modifyModel'
	export default {
		...modifyModel
	}
</script>

<style lang="less" scoped>
	.be_title{
		display: flex;
		// align-items: center;
		width:20%;
		
		.be_mandatory{
			font-size: 1vw;
			color: red;
		}
		
		.be_text{
			color: #888;
			font-size: 0.8vw;
		}
	}
	
	.input1{
		width: 5vw;
		height: 2vw;
		line-height: 2vw;
		text-align: center;
		border: 1px solid #d6d6d6;
		background: #fff;
	}
	
	.x{
		display: inline-block;
		font-size: 0.8vw;
		border: 1px solid #d6d6d6;
		text-align: center;
		background: #efefef;
		padding: 0 10px;
		display: flex;
		align-items: center;
	}
	
	.popover{
		position: absolute;
		top:32px;
		left: 0;
		width: 400px;
		border: 1px solid #d6d6d6;
		background: #fff;
		padding: 20px;
		z-index: 2;
	}
</style>

import { render, staticRenderFns } from "./index2.vue?vue&type=template&id=2641737c&scoped=true&"
import script from "./index2.vue?vue&type=script&lang=js&"
export * from "./index2.vue?vue&type=script&lang=js&"
import style0 from "./index2.vue?vue&type=style&index=0&id=2641737c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2641737c",
  null
  
)

export default component.exports